/* App.css */
:root {
    --primary-color: #0075c3;
    --secondary-color: #2c3e50;
    --background-color: #f4f4f4;
    --card-background: #ecf0f1;
    --text-color: #333;
    --delete-color: #e74c3c;
    --confirm-color: #27ae60;
    --cancel-color: #95a5a6;
    --hoverblue-color: #005791;
}

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--label-color);
    line-height: 1.5;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

.app-container {
    max-width: 1200px;
    margin: 1.5rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
}

h1 {
    text-align: center;
    color: var(--secondary-color);
    margin: 0;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.hidden {
    display: none;
}

textarea {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
    max-height: 600px;
    margin-bottom: 1rem;
    transition: border-color 0.3s ease;
}

textarea:focus {
    outline: none;
    border-color: var(--primary-color);
}

button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: white;
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover,
.button-container a:hover {
    opacity: 0.8;
}

.notescontent {
    margin-top: 2rem;
}

.notescontentlist {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.notescontentlist h2 {
    font-size: 1.1rem;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.notescontentlist .mainNoteContent {
    margin: 0 0 1rem 0;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.button-container button,
.button-container a {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    text-decoration: none;
    color: white;
}

.button-container a {
    background-color: var(--primary-color);
}

.delete-button {
    background-color: var(--delete-color);
}

.confirm-button {
    background-color: var(--confirm-color);
}

.cancel-button {
    background-color: var(--cancel-color);
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 0.5rem;
}

.pagination button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}

.pagination button.active {
    background-color: var(--secondary-color);
}

.search-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;
    transition: border-color 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.error-message {
    background-color: #fce4e4;
    border: 1px solid #fcc2c3;
    color: #cc0033;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

/* Editing mode styles */
.editing-mode {
    background-color: #fff;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.editing-mode form {
    margin-bottom: 0;
}

.editing-mode textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 120px;
    margin-bottom: 1rem;
    transition: border-color 0.3s ease;
}

.editing-mode textarea:focus {
    outline: none;
    border-color: var(--primary-color);
}

.editing-mode label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--secondary-color);
}

.editing-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.editing-buttons button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.save-button {
    background-color: var(--confirm-color);
}

.save-button:hover {
    background-color: #2ecc71;
}

.cancel-edit-button {
    background-color: var(--cancel-color);
}

.cancel-edit-button:hover {
    background-color: #7f8c8d;
}

/* App header styles */
.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.refresh-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.refresh-button:hover {
    background-color: #9595951a;
}

.refresh-icon {
    width: 24px;
    height: 24px;
}

.refresh-button:hover .refresh-icon {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.delete-lock-container {
    margin-bottom: 1rem;
}

.delete-lock-container label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.delete-lock-container input[type="checkbox"] {
    margin-right: 0.5rem;
}

.lock-button,
.unlock-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.unlock-button {
    background-color: #e74c3c;
}

.note-dates {
    font-size: 0.8em;
    color: #666;
    margin-top: 0.5em;
}

.note-dates p {
    margin: 0;
}

.history-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    max-height: 80%;
    width: 1100px;
    overflow-y: auto;
    z-index: 1000;
}

.history-item {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

.history-item .history-item-content {
    word-wrap: break-word;
    margin: 0 0 1rem;
    white-space: pre-wrap;
}

.history-item:last-child {
    border-bottom: none;
}

.loading-bar {
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
}

.loading-bar::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    animation: loading 2s infinite ease-in-out;
}

@keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(400%); }
}

.loadingStatus {
    text-align: center;
}

.character-count {
    text-align: right;
    font-size: 0.8em;
    color: #666;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
}

.character-count.near-limit {
    color: #e74c3c;
}

.bookmark-button,
.bookmarked-button {
    background-color: #f39c12;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.bookmarked-button {
    background-color: #d35400;
}

.passcode-entry {
    max-width: 300px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}

.passcode-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.passcode-inputs input {
    width: 40px;
    height: 40px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.passcode-entry button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.passcode-entry .error {
    color: red;
    margin-top: 10px;
}

.passcode-entry input:disabled,
.passcode-entry button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.passcode-entry p.error {
    margin: 0;
}

.notescontentlist.collapsed .mainNoteContent {
    display: none;
}

.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notescontentlist .mainNoteContent {
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
}

.notescontentlist.collapsed .mainNoteContent {
    max-height: 0;
    opacity: 0;
}

.note-content {
    overflow-wrap: break-word;
}

.tags {
    margin-bottom: 10px;
}

.tags span {
    margin-right: 5px;
}

.hiddenContentCheckbox {
    width: 20px;
    height: 20px;
}

.auto-tag {
    background-color: #e0f7fa;
    font-style: italic;
}

.auto-tags {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
}

.auto-tags .auto-tag {
    margin-right: 5px;
    padding: 2px 5px;
    border-radius: 3px;
}

.tags input[type="text"] {
    border: 1px #e0e0e0 solid;
    color: #3f3f3f;
    font-size: 0.9rem;
    padding: 3px;
    border-radius: 3px;
}

.tags button {
    display: inline-grid;
    padding: 0px;
    font-size: 1rem;
    color: white;
    background-color: #ff5353;
    border: none;
    border-radius: 60px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    text-align: center;
    margin-left: 5px;
}

.tags button:hover {
    background-color: #cf0000;
}

.links-list {
    margin-top: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    overflow-wrap: break-word;
}

.links-list ul {
    list-style-type: none;
    padding: 0;
}

.links-list li {
    margin-bottom: 10px;
}

.links-list a {
    color: #0066cc;
    text-decoration: none;
}

.links-list a:hover {
    text-decoration: underline;
}

.links-list h1 {
    margin-bottom: 20px;
}

.links-list-items {
    list-style-type: auto;
    padding: 0;
}

.link-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.link-url {
    color: #0066cc;
    text-decoration: none;
    font-weight: bold;
}

.link-notes {
    font-style: italic;
}

.note-link {
    color: #009900;
    text-decoration: none;
}

.total-links {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
    .app-container {
        padding: 1rem;
        margin: 1rem;
    }

    textarea,
    .search-input,
    .AutoExpandingTextarea {
        font-size: 16px; /* Prevents zoom on mobile */
    }

    .button-container {
        flex-wrap: wrap;
    }

    .button-container button,
    .button-container a {
        flex: 1 0 auto;
        text-align: center;
    }

    .editing-mode {
        padding: 1rem;
    }

    .editing-buttons {
        flex-direction: column;
    }

    .editing-buttons button {
        width: 100%;
    }
}



.notescontentlist.collapsed {
    background-color: #fffbeb;
}

.notescontentlist.collapsed .button-container {
    display: none;
}


/* Additional styles for links list */
.links-list {
  padding: 0 20px;
}

.links-list h1 {
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.pagination button.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination button:hover:not(.active):not(:disabled) {
  background: #e2e2e2;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  margin-right: 10px;
}

.pagination-top {
  margin-top: 0;
}

.retry-button {
  padding: 5px 10px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.retry-button:hover {
  background: #e2e2e2;
}

.back-to-first-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}




/* Debug panel styles */
.debug-panel {
  margin-top: 30px;
  padding: 15px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.debug-toggle-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.debug-info {
  margin-top: 10px;
}

.debug-info pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 3px;
  overflow-x: auto;
}

.debug-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.debug-actions button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

/* Additional styles for links list */
.links-list {
  padding: 0 20px;
}

.links-list h1 {
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.pagination button.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination button:hover:not(.active):not(:disabled) {
  background: #e2e2e2;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  margin-right: 10px;
}

.pagination-top {
  margin-top: 0;
}

.retry-button {
  padding: 5px 10px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.retry-button:hover {
  background: #e2e2e2;
}

.back-to-first-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}





.cache-manager {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.preheat-controls {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.preheat-controls label {
  font-weight: bold;
  color: var(--secondary-color);
}

.preheat-controls select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.preheat-controls button {
  background-color: var(--primary-color);
}

.preheat-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.preheat-message {
  margin-top: 10px;
  color: var(--confirm-color);
}

.preheat-message.error {
  color: var(--delete-color);
}

.cache-stats {
  background-color: var(--card-background);
  padding: 15px;
  border-radius: 8px;
}

.cache-stats h3 {
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.cache-stats p {
  margin: 5px 0;
}

.cache-stats .error {
  color: var(--delete-color);
}

.network-status {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.status-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.status-code {
  font-size: 0.9rem;
  color: var(--text-color);
}



.pulse {
    background: rgb(222, 84, 72);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    box-shadow: 0 0 0 0 rgba(222, 84, 72, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(222, 84, 72, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(222, 84, 72, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(222, 84, 72, 0);
    }
}           



/* Copy button styles */
.button-container button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    color: white;
}

/* Specific style for the copy button */
.button-container button.copy-button {
    background-color: #3498db; /* Blue for copy */
}

.button-container button.copy-button:hover {
    background-color: #2980b9;
}

/* Style when copied */
.button-container button.copied {
    background-color: var(--confirm-color); /* Green for success */
}

/* Style when failed */
.button-container button.failed {
    background-color: var(--delete-color); /* Red for failure */
}









/* Link metadata styles */
.link-metadata {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.link-metadata h3 {
    font-size: 1rem;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.metadata-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    gap: 0.5rem;
}

.metadata-image {
    width: 120px;
    height: 68px;
    object-fit: cover;
    border-radius: 4px;
}

.metadata-details {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.metadata-details a {
    color: var(--primary-color);
    text-decoration: none;
}

.metadata-details a:hover {
    text-decoration: underline;
}

.remove-metadata-button {
    align-items: center;
    background-color: #e74c3c;
    background-color: var(--delete-color);
    border: none;
    border-radius: 60px;
    color: #fff;
    cursor: pointer;
    display: inline-grid;
    font-size: 1rem;
    height: 20px;
    justify-content: center;
    width: 20px;
    padding: 0;
}

.remove-metadata-button:hover {
    background-color: #c0392b;
}



/* Link metadata container */
.link-metadata-container {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.metadata-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.metadata-header h3 {
    font-size: 1rem;
    color: var(--secondary-color);
    margin: 0;
}

.toggle-metadata-button {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    color: var(--secondary-color);
    transition: transform 0.3s ease;
}

.toggle-metadata-button:hover {
    color: var(--primary-color);
}

.link-metadata {
    margin-top: 0.5rem;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    /* max-height: 500px;  Adjust as needed */
    opacity: 1;
    overflow: hidden;
}

/* Ensure smooth collapse animation */
.notescontentlist.collapsed .link-metadata {
    max-height: 0;
    opacity: 0;
}



